<template>
	<el-container>
		<el-header>
			<div class="openclose">
				<el-menu :default-active="activeIndex" mode="horizontal" @select="select" class="menu">
					<el-menu-item v-for="(item, index) in routers" :key="index" :index="item.path"
						v-show="item.meta.show">
						<span>
							{{ item.name }}
						</span>
						<span v-if=" item.path == '/chushen2' ">({{ total }})</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="dropdown">
				<el-dropdown>
					<span class="el-dropdown-link">
						<span>{{ username }}</span>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item @click="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</el-header>
		<el-container>
			<el-main style="width: 100%;">
				<router-view />
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
import Cookies from "js-cookie";
import { ref ,reactive} from "vue";
import constant from "@/constant";
import { useRouter } from "vue-router";
export default {
	data() {
		return {
			routers: [],
			total: 0,
			isCollapse: ref(false),
			username: '',
			activeIndex: '/OrderList',
			asidewidth: ref('10%'),
			key: ref('1'),
		}
	},
	methods: {
		select(key) {
			console.log(key)
			this.axios.post(constant.read, {
				type: '', formInline: reactive({
					name: '',
					ywyid: '',
					date: '',
				}), page: 1
			}).then((res) => {
				console.log(res);
				this.total = res.data.total;
				console.log(this.total)
			}).catch((err) => {
				console.log(err);
			});
			this.$router.push(key)
		},
		logout() {
			Cookies.remove('token');
			Cookies.remove('name');
			Cookies.remove('patharr');
			Cookies.remove('ismerchant');
			Cookies.remove('mid');
			this.$router.push({ path: '/' });
		}
	},
	created: function () {
		this.username = Cookies.get('name')
		const router = useRouter();
		const routers = router.options.routes
		for (let index = 0; index < routers.length; index++) {
			const element = routers[index];
			if (element.path == "/SparkFire") {
				this.routers = element.children
			}
		}
		this.activeIndex = this.$route.path
		this.axios.post(constant.read, {
			type: '', formInline: reactive({
				name: '',
				ywyid: '',
				date: '',
			}), page: 1 }).then((res) => {
			console.log(res);
			this.total = res.data.total;
			console.log(this.total)
		}).catch((err) => {
			console.log(err);
		});
		console.log(this.$route)
	}
};
</script>
<style>
.container .el-aside {
	height: 100%;
	transition: all .5s;
	overflow-y: auto;
	overflow-x: hidden;
}

.el-header {
	display: flex;
	align-items: center;
	border-bottom: solid 1px #e6e6e6;
}

.dropdown {
	cursor: pointer;
	justify-content: flex-end;
}

.openclose {
	flex: 1;
	justify-content: flex-start;
}

.flex-grow {
	flex-grow: 1;
}
</style>