<template>
    <el-card>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="业绩统计" name="first">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24" style="text-align:left;">
                            <div class="searchblock">
                                <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至"
                                    start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts"
                                    style="margin-right:10px;" size="small"
                                    ></el-date-picker>
                                <el-input v-model="keyword" placeholder="请输入业务员姓名" prefix-icon="el-icon-search"
                                    class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;"
                                    class="hidden-sm-and-down" size="small">搜索</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-table style="width:100%;margin: 15px 0" :max-height="viewportHeight" :data="tabledata" border
                    size="small">
                    <el-table-column label="业务员姓名" prop="ywyname"></el-table-column>
                    <el-table-column label="机价总和(元)" prop="Margin"></el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :current-page="curpage" size="small" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                    layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                    style="text-align:center"></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="业务员管理" name="three">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24" style="text-align:left;">
                            <div class="searchblock">
                                <el-input v-model="keyword" placeholder="请输入业务员姓名" prefix-icon="el-icon-search"
                                    class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="settle" style="margin-left:10px;"
                                    class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="primary" @click="addyewuyuan" style="margin-left:10px;"
                                    class="hidden-sm-and-down" size="small">添加业务员</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-table style="margin: 15px 0" :max-height="viewportHeight" :data="tabledatasettle" border
                    size="small">
                    <el-table-column label="业务员姓名" width="100" prop="ywyname"></el-table-column>
                    <el-table-column label="所属代理商" prop="Merchant"></el-table-column>
                    <el-table-column label="业务员账户" prop="account"></el-table-column>
                    <el-table-column label="提成比例" prop="percent">
                        <template #default="scope">
                            <el-input v-model="scope.row.percent" :disabled="inputid != scope.row.id"
                                style="width: 111px;" placeholder="请输入比例" size="small" clearable />
                            <el-tag class="mx-1" effect="plain" round size="small" @click="bianji(scope.row.id)"
                                style="cursor: pointer;margin-left:10px;"
                                v-if="(!dis && inputid != scope.row.id) || dis">
                                编辑
                            </el-tag>
                            <el-tag class="mx-1" effect="plain" round size="small"
                                @click="save(scope.row.id, scope.row.percent)" style="cursor: pointer;margin-left:10px;"
                                v-if="!dis && inputid == scope.row.id">
                                保存
                            </el-tag>
                            <el-tag class="mx-1" effect="plain" round size="small" @click="cancel"
                                style="cursor: pointer;margin-left:10px;" v-if="!dis && inputid == scope.row.id">
                                取消
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="罚款比例" prop="penalty">
                        <template #default="scope">
                            <el-input v-model="scope.row.penalty" :disabled="inputid2 != scope.row.id"
                                style="width: 111px;" placeholder="请输入比例" size="small" clearable />
                            <el-tag class="mx-1" effect="plain" round size="small" @click="bianji2(scope.row.id)"
                                style="cursor: pointer;margin-left:10px;"
                                v-if="(!dis && inputid2 != scope.row.id) || dis">
                                编辑
                            </el-tag>
                            <el-tag class="mx-1" effect="plain" round size="small"
                                @click="save2(scope.row.id, scope.row.penalty)"
                                style="cursor: pointer;margin-left:10px;" v-if="!dis && inputid2 == scope.row.id">
                                保存
                            </el-tag>
                            <el-tag class="mx-1" effect="plain" round size="small" @click="cancel2"
                                style="cursor: pointer;margin-left:10px;" v-if="!dis && inputid2 == scope.row.id">
                                取消
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="添加时间" prop="addtime">
                        <template #default="scope">
                            {{ scope.row.addtime }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange2" @size-change="handleSizeChange2"
                    :current-page="curpage" size="small" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                    layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                    style="text-align:center"></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="工资统计" name="second">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24" style="text-align:left;">
                            <div class="searchblock">
                                <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至"
                                    start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts"
                                    style="margin-right:10px;" size="small"
                                    ></el-date-picker>
                                <el-input v-model="keyword" placeholder="请输入业务员姓名" prefix-icon="el-icon-search"
                                    class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="statistics" style="margin-left:10px;"
                                    class="hidden-sm-and-down" size="small">搜索</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-table style="margin: 15px 0" :max-height="viewportHeight" :data="salary" border size="small">
                    <el-table-column label="业务员姓名" prop="ywyname"></el-table-column>
                    <el-table-column label="工资/提成总和(元)" prop="salary">
                        <template #default="scope">
                            {{ scope.row.salary }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" prop="id">
                        <template #default="scope">
                            <el-tag size="small" style="cursor: pointer;"
                                @click="detail(scope.row.id, scope.row.ywyname)">查看详情</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange3" @size-change="handleSizeChange3"
                    :current-page="curpage" size="small" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                    layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                    style="text-align:center"></el-pagination>
            </el-tab-pane>
        </el-tabs>
    </el-card>
    <el-dialog v-model="dialogVisibleadd" title="添加业务员" width="24%">
        <span style="margin-bottom: 10px;display: block;">业务员姓名:</span>
        <el-input v-model="ywyname" placeholder="请输入业务员姓名" />
        <span style="margin: 10px 0;display: block;">业务员手机号:</span>
        <el-input v-model="account" placeholder="请输入业务员手机号" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisibleadd = false">关闭</el-button>
                <el-button type="primary" @click="addywy">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import qs from 'qs'
import Cookies from 'js-cookie'
import { ref } from 'vue'
import constant from '@/constant'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data() {
        return {
            viewportHeight: ref(0),
            salary: ref([]),
            inputid: ref(0),
            inputid2: ref(0),
            dis: ref(false),
            tabledatasettle: ref([]),
            activeName: ref('first'),
            account: ref(),
            ywyname: ref(),
            executing: false,
            datetimerange: [
                new Date(2022, 1, 1, 0, 0, 0),
                new Date(2022, 1, 1, 23, 59, 59)
            ],
            dialogVisibleadd: ref(false),
            ongoing: 0,
            complete: 0,
            shortcuts: [
                {
                    text: '今天',
                    value: () => {
                        const start = new Date();
                        let ret_start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
                        let ret_end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 59);
                        console.log('s' + ret_start);
                        console.log('e' + ret_end);
                        return [ret_start, ret_end]
                    }
                }
            ],
            TradeType: [
                {
                    name: '全部',
                    status: '0',
                },
                {
                    name: '提现中',
                    status: '1',
                },
                {
                    name: '已完成',
                    status: '2',
                },
            ],
            cashoutdetail: false,
            dialogImageUrl: '',
            dialogVisible: false,
            upload_url: constant.upload_temp_img_url,
            completecashout: ref(false),
            completecashout2: false,
            logdialog: false,
            curpage: 1,
            pagesize: 10,
            totalcount: 0,
            range: [],
            keyword: '',
            active2: ref('ywy'),
            status: '',
            editcashout: false,
            addcashout: false,
            checkcashout: false,
            tabledata: [],
            log: [],
            cashoutform: {
                Credit: '',
                Merchant: '',
                MerchantID: this.mid,
                Account: '',
                AccountName: '',
                Amount: 0,
                ID: '',
                tradeno: [
                    {
                        value: '',
                    }
                ],
                images: [],
                CompleteDTime: '',
            },
            rule: {
                Account: [{
                    required: true,
                    message: '请输入提现账户',
                    trigger: 'blur'
                }],
                AccountName: [{
                    required: true,
                    message: '请输入提现账户姓名',
                    trigger: 'blur'
                }],
                Amount: [{
                    required: true,
                    message: '请输入提现金额',
                    trigger: 'blur'
                }]
            },
            cashoutinfo: {},
            merchants: [],
            merchantid: '',
            userid: "",
            item: ""
        }
    },
    mounted() {
        // 监听窗口大小变化事件  
        window.addEventListener('resize', this.handleResize);
        // 初始化时获取一次视口高度  
        this.viewportHeight = (window.innerHeight - 230) + "px";
        console.log(this.viewportHeight)
    },
    beforeUnmount() {
        // 移除事件监听器，防止内存泄漏  
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        '$route.query.someIdentifier'(newVal) {
            if (newVal) {
                // 处理从组件B返回时传递的额外信息  
                console.log(newVal)
            }
        }
    },
    methods: {
        handleResize(height = 230) {
            // 更新视口高度  
            this.viewportHeight = (window.innerHeight - height) + "px";
        },
        cancel2() {
            this.inputid2 = 1000000000000000
        },
        cancel() {
            this.inputid = 1000000000000000
        },
        detail(id, ywyname) {
            this.$router.push({
                path: '/SalaryDetails', query: {
                    id: id,
                    ywyname: ywyname
                }
            });
        },
        bianji(id) {
            console.log(id)
            this.inputid = id
            this.dis = false
        },
        save(id, val) {
            console.log(id, val)
            this.axios.get(constant.savepercent, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id,
                    percent: val,
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dis = true
                    this.inputid = 1000000000000
                } else {
                    this.$message.error("保存失败")
                }
            })
        },
        save2(id, val) {
            console.log(id, val)
            this.axios.get(constant.savepenalty, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id,
                    penalty: val,
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dis = true
                    this.inputid2 = 1000000000000
                } else {
                    this.$message.error("保存失败")
                }
            })
        },
        bianji2(id) {
            console.log(id)
            this.inputid2 = id
            this.dis = false
        },
        handleClick(tab) {
            console.log(tab.props.name)
            switch (tab.props.name) {
                case "three":
                    this.curpage = 1
                    this.pagesize = 10
                    this.settle();
                    this.handleResize(260)
                    break;
                case "second":
                    ElMessage({
                        message: "请及时处理好罚款，以保证数据的准确性。",
                        type: "error",
                        duration: 2500
                    })
                    this.curpage = 1
                    this.pagesize = 10
                    this.statistics();
                    this.handleResize(260)
                    break;
                case "first":
                    this.curpage = 1
                    this.pagesize = 10
                    this.init();
                    break;
            }
        },
        settle() {
            this.axios.get(constant.getywysettle, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                }
            }).then((response) => {
                console.log(response)
                if (response.data.list.length > 0) {
                    this.tabledatasettle = response.data.list
                    this.curpage = response.data.curpage * 1;
                    this.totalcount = response.data.totalcount * 1;
                } else {
                    this.tabledatasettle = response.data.list
                }
            })
        },
        addywy() {
            this.axios.get(constant.addywy, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    ywyname: this.ywyname,
                    account: this.account
                }
            }).then((response) => {
                if (response.data.code == 200) {
                    this.$message.success("添加成功");
                    this.dialogVisibleadd = false
                } else if (response.data.code == 300) {
                    this.$message.warning("姓名重复，请重新填写");
                } else {
                    this.$message.error("添加失败");
                }
            })
        },
        addyewuyuan() {
            this.dialogVisibleadd = true
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange2(val) {
            console.log(val);
            this.curpage = val;
            this.settle();
        },
        handleSizeChange2(val) {
            console.log(val);
            this.pagesize = val;
            this.settle();
        },
        handleCurrentChange3(val) {
            console.log(val);
            this.curpage = val;
            this.statistics();
        },
        handleSizeChange3(val) {
            console.log(val);
            this.pagesize = val;
            this.statistics();
        },
        addtradeno() {
            this.cashoutform.tradeno.push({
                value: ''
            })
        },
        deltradeno(index) {
            this.cashoutform.tradeno.splice(index, 1);
        },
        statistics() {
            this.axios.get(constant.salary, {
                headers: {
                    "content-type": "application/sjon"
                },
                params: {
                    range: JSON.stringify(this.range),
                    keyword: this.keyword,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data);
                this.salary = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        Edit(id) {
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.editcashout = true;
                this.cashoutform = response.data;
            });
        },
        Check(id) {
            console.log(id);
            this.axios.get(constant.checkcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.cashoutform = response.data;
                this.cashoutdetail = true;
            });
        },
        Close() {
            this.cashoutdetail = false;
        },
        confirmclick() {
            ElMessageBox.confirm(
                '确定提现?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    console.log(this.cashoutform)
                    this.axios.post(constant.alipay, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                        console.log(response)
                        if (response.data.code == 10000) {
                            ElMessage({
                                type: "success",
                                message: "提现成功"
                            })
                            this.item.Status = 2
                            this.completecashout = false
                        } else {
                            ElMessage({
                                type: "error",
                                message: response.data.sub_msg
                            })
                        }
                    })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消提现',
                    })
                })
        },
        Complete2(id) {
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.completecashout2 = true;
                this.cashoutform = response.data;
                this.cashoutform.tradeno = [{
                    value: '',
                }];
                this.cashoutform.images = [];
                this.completecashout = false
            });
        },
        Complete(item, id) {
            this.item = item
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.completecashout = true;
                this.cashoutform = response.data;
                // this.cashoutform.tradeno = [{
                //     value:'',
                // }];
                // this.cashoutform.images = [];
            });
        },
        SaveComplete() {
            if (this.cashoutform.tradeno.length == 1) {
                if (!this.cashoutform.tradeno[0].value) {
                    this.$message.error("必须填写至少一条交易流水号");
                    return false;
                }
            }
            if (this.cashoutform.images.length <= 0) {
                this.$message.error("必须上传至少一张交易完成截图");
                return false;
            }
            this.$refs['cashoutform'].validate((valid) => {
                if (valid) {
                    console.log(this.cashoutform);
                    this.executing = true;
                    this.axios.post(constant.completecashout, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == "OK") {
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        } else {
                            this.$message.error("操作失败");
                        }
                        this.executing = false;
                    });
                } else {
                    return false;
                }
            });
        },
        CancelComplete() {
            this.completecashout2 = false;
        },
        Update() {
            if (this.cashoutform.Amount * 1 > this.cashoutform.Credit * 1) {
                this.$message.error("提现金额不能超过当前可提现额度");
                return false;
            }
            if (this.cashoutform.Amount * 1 < 200 || this.cashoutform.Amount * 1 > 100000) {
                this.$message.error("提现额度必须在200到100000之间");
                return false;
            }
            this.axios.post(constant.updatecashout, qs.stringify(this.cashoutform), {
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功");
                    this.$router.go(0);
                } else {
                    this.$message.error("操作失败");
                }
            });
        },
        switchtab() {
            console.log(this.status);
            this.axios.get(constant.cashoutlist, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    curpage: 1,
                    pagesize: 10,
                    keyword: this.keyword,
                    merchantid: this.merchantid,
                    range: JSON.stringify(this.range),
                    status: this.status,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        init() {
            this.axios.get(constant.get_ywy, {
                headers: {
                    "content-type": "application/sjon"
                },
                params: {
                    range: JSON.stringify(this.range),
                    keyword: this.keyword,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        add() {
            this.axios.get(constant.addcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchantid: this.mid
                }
            }).then((response) => {
                console.log(response.data);
                let credit = (response.data * 1) / 100;
                this.cashoutform.Credit = credit;
                this.addcashout = true;
            });
            console.log(this.cashoutform);
        },
        CancelAdd() {
            this.addcashout = false;
            this.editcashout = false;
        },
        Confirm() {
            console.log("a" + this.cashoutform.Amount + "c" + this.cashoutform.Credit);
            //return false;
            if (this.cashoutform.Amount * 1 < 0.1 || this.cashoutform.Amount * 1 > 500000) {
                this.$message.error("不符合提现需求");
                return false;
            }
            if (this.cashoutform.Amount * 1 > this.cashoutform.Credit * 1) {
                this.$message.error("不能超过可提现额度");
                return false;
            }
            this.$refs['cashoutform'].validate((valid) => {
                if (valid) {
                    console.log(this.cashoutform);
                    this.axios.post(constant.savecashout, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        },
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == 'OK') {
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        } else if (response.data == 'HAVEONGOING') {
                            this.$message.error("还有提现中的申请,请等待提现完成之后再进行操作");
                        } else if (response.data == 'NOAMOUNT') {
                            this.$message.error("提现金额不能为0");
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        UploadExceed(file, filelist) {
            this.$message.warning(`当前限制选择 10 个图片,本次选择了 ${file.length} 个文件,共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response, file, filelist) {
            console.log(file);
            console.log(filelist);
            console.log(response);
            if (response.error == '0') {
                this.cashoutform.images = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if (filelist[i]['response']) {
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.cashoutform.images.push(obj);
                }
            }
            console.log(this.cashoutform.images);
        },
        RemovePic(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.cashoutform.images = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.cashoutform.images.push(obj);
            }
        },
        CheckFile(file) {
            console.log(file);
            if (file.type != "image/jpg" && file.type != "image/png" && file.type != "image/jpeg") {
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
        },
        UploadError(err) {
            this.$message.error(err);
        },
        Preview(file) {
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else {
                this.dialogImageUrl = file.url;
            }
            this.dialogVisible = true;
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.userid = Cookies.get("id");
        if (this.mid) {
            this.cashoutform.MerchantID = this.mid;
            this.merchantid = this.mid;
        }
        this.handleResize()
        this.init();
        if (this.$route.query.name) {
            this.activeName = this.$route.query.name
            switch (this.$route.query.name) {
                case "three":
                    this.curpage = 1
                    this.pagesize = 10
                    this.settle();
                    this.handleResize(260)
                    break;
                case "second":
                    ElMessage({
                        message: "请及时处理好罚款，以保证数据的准确性。",
                        type: "error",
                        duration: 2500
                    })
                    this.curpage = 1
                    this.pagesize = 10
                    this.statistics();
                    this.handleResize(260)
                    break;
                case "first":
                    this.curpage = 1
                    this.pagesize = 10
                    this.init();
                    break;
            }
        }

    }
}
</script>
<style scoped>
.el-form-item {
    margin-bottom: 15px;
}

.el-tabs__header {
    margin: 0 !important;
}

.el-card__body {
    padding: 10px 20px !important;
}

.info {
    padding: 5px 10px;
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.info * {
    padding: 2px;
}
</style>